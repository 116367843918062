<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="8" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
							<b-form-input v-model="title" v-validate="'required'" data-vv-name="title">
							</b-form-input>
							<div v-if="errors.has('title')">
								<p class="text-danger">{{ errors.first('title') }}</p>
							</div>
						</b-form-group>
						<!-- <b-form-group v-bind:class="{ 'is-invalid': errors.has('slug')}" label="Slug">
							<b-form-input v-model="models.slug" disabled>
							</b-form-input>
						</b-form-group> -->

						<b-form-group v-bind:class="{ 'is-invalid': errors.has('description')}" label="Description">
							<Editor :value="models.description" @input="value => models.description = value"/>
							<div v-if="errors.has('description')">
								<p class="text-danger">{{ errors.first('description') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="4" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.image)" class="mb-2" style="max-height: 160px; border: none"/>
							<b-button class="btn-upload btn-block" @click="$refs.filemanager.show('image')">
									<i class="fa fa-upload"></i> Upload Image
							</b-button>
						</div>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.keyword"
							placeholder="Meta Keyword"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.description"
							placeholder="Meta Description"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-group>
							<b-form-radio-group
								v-model="models.status"
								name="status"
							>
								<b-form-radio value="publish">Publish</b-form-radio>
								<b-form-radio value="draft">Draft</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
		<Filemanager ref="filemanager" name="news" @selectMedia="selectMedia"/>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import {
		getUrlOfFile,
		// str_slug
	} from '@/utils/helpers.js';
	import Editor from '@/components/editor';
	import Filemanager from '@/components/file_manager';
	import ResponseAlert from '@/components/response_alert';

	export default {
		name: 'FormPage',
		components: {
			Editor,
			ResponseAlert,
			Filemanager
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					title: '',
					description: '',
					image: '',
					meta: {
						description: '',
						keyword: ''
					},
					status: ''
				},
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		computed: {
			title: {
				get(){
					return this.models.title
				},
				set(value){
					this.models.title = value
					// this.models.slug = str_slug(value)
				}
			}
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.page}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title;
							_.models.description = data.description;
							_.models.image = data.image;
							_.models.meta.description = data.meta.description;
							_.models.meta.keyword = data.meta.keyword;
							_.models.status = data.status;
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},
			selectMedia(field, media) {
				const _ = this;
				_.models[field] = media.file;
			},
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						if (!_.$route.params.id) {
							request = _.$axios.post(api.page, _.models);
						} else {
							request = _.$axios.put(`${api.page}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => _.$router.push({
								name: _.config.uri
							}))
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		},
	}
</script>